/* For license logos */
const css = String.raw;

// Prettier ignore only to keep code indented from level 0.
// prettier-ignore
export default css`
img.license {
  float: left;
  padding-right: 5px;
  background: none;
}
`;
